import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import animateScrollTo from "animated-scroll-to"
import Button from "components/controls/button"
import SectionHeader from "components/sectionHeader"
import BreakpointContext from "context/breakpointContext"
import { useGridDimensions } from "context/gridDimensionsContext"
import { detect } from "detect-browser"
import { Link } from "gatsby"
import { size } from "helpers/responsiveSize"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import React, { useContext, useRef, useState } from "react"

//
// A rail of scrolling content
//
const ContentRail = ({ title, children, collectionId, className }) => {
  // Mount state
  const isMounted = useMountOnLoad()

  // Browser sniffer
  const browser = detect()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Reference for the scroll container
  const scrollContainer = useRef(null)

  // Enable/disable scroll snapping
  const [isScrollSnapping, setScrollSnapping] = useState(false)

  // Detect if this is manual (button-based) scrolling
  const [isManualScrolling, setManualScrolling] = useState(false)

  // Grid dimensions from context
  const { offsetWidth, gutterWidth } = useGridDimensions()

  // Scroll target position
  // const [scrollTarget, setScrollTarget] = useState(0)

  // Scroll direction
  // const [scrollDirection, setScrollDirection] = useState(undefined)

  // A timeout for determining if scrolling is still happening
  const [isScrollingTimout, setScrollingTimout] = useState(false)

  // See:  https://github.com/Stanko/animated-scroll-to
  const easeCubicInOut = (t) => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  }

  //
  // Scroll the scroll container manually
  //
  const manualScroll = (direction) => {
    // Get a reference to the scroll container
    const container = scrollContainer.current

    // Indicate that manual scrolling has started
    setManualScrolling(true)

    // Switch off scroll snapping temporarily when manual scrolling
    setScrollSnapping(false)

    // Current scroll offset
    const scrollOffset = container.scrollLeft

    // Scroll target
    let scrollTarget
    if (direction === "right") {
      // setScrollDirection("right")
      scrollTarget = scrollOffset + window.innerWidth
      if (browser.name !== "chrome") {
        scrollTarget = scrollTarget - 100 // Make sure the item is fully visible!
      }
    } else if (direction === "left") {
      // setScrollDirection("left")
      scrollTarget = scrollOffset - window.innerWidth
      if (browser.name !== "chrome") {
        scrollTarget = scrollTarget + 100
      }
    } else {
      throw Error("Unrecognised scroll direction!")
    }
    // setScrollTarget(scrollTarget)

    // Animate scrolling
    animateScrollTo([scrollTarget, null], {
      elementToScroll: container,
      speed: 750,
      easing: easeCubicInOut,
    })
  }

  //
  // Handle a scroll event
  //
  const handleOnScroll = (event) => {
    // Record state of scrolling
    clearTimeout(isScrollingTimout)
    setScrollingTimout(
      setTimeout(() => {
        // Indicate that manual scrolling has completed
        setManualScrolling(false)
      }, 250)
    )

    // Switch on scroll snapping immediately when smooth scrolling
    if (!isManualScrolling) {
      setScrollSnapping(true)
      return
    }

    // Switch on scroll snappping for Chrome only when
    // manual scrolling (Safari has weird bugs if you do this!)
    // if (isManualScrolling && browser.name === "chrome") {
    //   // Give extra scroll offsets to ensure snapping aligns nicely with content
    //   // items on desktop scrolls.
    //   const element = event.currentTarget
    //   const scrollOffset = element.scrollLeft
    //   if (scrollDirection === "left") {
    //     if (scrollOffset <= scrollTarget + 125) {
    //       setScrollSnapping(true)
    //     }
    //   } else if (scrollDirection === "right") {
    //     if (scrollOffset >= scrollTarget - 125) {
    //       setScrollSnapping(true)
    //     }
    //   }
    // }
  }

  return (
    <>
      {/* Render an invisible rail for SEO during SSR rendering, using key to help with rehydration */}

      {/*
       * TODO
       * Sort out SEO rendering, so that it doesn't screw up the React rehydration
       * algorithm.  Ideally, we would render an invisible container here that has some
       * metadata in it.  Unfortunately, as soon as I try that, it seems to leave
       * bits of unreconciled structure inthe DOM, even if I use React Key prop.
       */}
      {/* {!mounted && (
        <div key={id} className="invisible">
          <p>{title}</p>
          {children}
        </div>
      )} */}

      {/* Only show the rail properly in a real browser */}
      {isMounted && (
        <div
          className={`
            col-start-1
            col-span-full
            ${className}
          `}
        >
          {/* Header container */}
          <div
            className="
              mt-7 tablet:mt-8 laptop:mt-10 desktop:mt-10
              flex
              justify-between
              items-center"
          >
            {/* Title */}
            <SectionHeader text={title} verticalPadding={false} />

            {/* Scroll buttons for devices without touch */}
            <div className="hidden notouch:flex">
              <Button
                rounded="rounded-tl-3xl rounded-bl-3xl rounded-tr-lg rounded-br-lg"
                size={size(bpt, undefined, "xs", "md", "lg")}
                shadowHeight={2}
                justifyChildren="start"
                onClick={() => {
                  manualScroll("left")
                }}
                style={{
                  width: size(bpt, undefined, undefined, "3.5vw", "3.5vw"),
                  height: size(bpt, undefined, undefined, "2vw", "2vw"),
                }}
                className="laptop:mr-2 desktop:mr-2.5"
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  style={{
                    width: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                    height: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                    marginLeft: size(
                      bpt,
                      undefined,
                      undefined,
                      "0.5vw",
                      "0.5vw"
                    ),
                  }}
                />
              </Button>
              <Button
                rounded="rounded-tr-3xl rounded-br-3xl rounded-tl-lg rounded-bl-lg"
                size={size(bpt, undefined, "xs", "md", "lg")}
                shadowHeight={2}
                justifyChildren="end"
                onClick={() => {
                  manualScroll("right")
                }}
                style={{
                  width: size(bpt, undefined, undefined, "3.5vw", "3.5vw"),
                  height: size(bpt, undefined, undefined, "2vw", "2vw"),
                }}
              >
                <FontAwesomeIcon
                  icon="angle-right"
                  style={{
                    width: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                    height: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                    marginRight: size(
                      bpt,
                      undefined,
                      undefined,
                      "0.5vw",
                      "0.5vw"
                    ),
                  }}
                />
              </Button>
            </div>

            {/* Control buttons */}
            {/* <div
              className="
                flex
                items-center
                self-end tablet:self-auto"
            >
              {collectionId && (
                <Button
                  text="See All"
                  flat={isTouchDevice ? false : true}
                  shadowHeight={1}
                  size={size(bpt, "xxs", "xxs", "xs", "md")}
                  style={{
                    width: size(bpt, "20vw", "9vw", "7vw", "7vw"),
                    marginRight: size(
                      bpt,
                      undefined,
                      undefined,
                      "0.5vw",
                      "1vw"
                    ),
                    marginBottom: size(
                      bpt,
                      "2vw",
                      "-0.8vw",
                      "-0.5vw",
                      "-0.5vw"
                    ),
                  }}
                  onClick={() => {
                    navigate(`/store/collection/${collectionId}`)
                  }}
                />
              )}

              <div className="hidden notouch:flex">
                <Button
                  rounded="rounded-tl-3xl rounded-bl-3xl rounded-tr-lg rounded-br-lg"
                  size={size(bpt, undefined, "xs", "md", "lg")}
                  shadowHeight={2}
                  justifyChildren="start"
                  onClick={() => {
                    manualScroll("left")
                  }}
                  style={{
                    width: size(bpt, undefined, undefined, "3.5vw", "3.5vw"),
                    height: size(bpt, undefined, undefined, "2vw", "2vw"),
                  }}
                  className="laptop:mr-2 desktop:mr-2.5"
                >
                  <FontAwesomeIcon
                    icon="angle-left"
                    style={{
                      width: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                      height: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                      marginLeft: size(
                        bpt,
                        undefined,
                        undefined,
                        "0.5vw",
                        "0.5vw"
                      ),
                    }}
                  />
                </Button>
                <Button
                  rounded="rounded-tr-3xl rounded-br-3xl rounded-tl-lg rounded-bl-lg"
                  size={size(bpt, undefined, "xs", "md", "lg")}
                  shadowHeight={2}
                  justifyChildren="end"
                  onClick={() => {
                    manualScroll("right")
                  }}
                  style={{
                    width: size(bpt, undefined, undefined, "3.5vw", "3.5vw"),
                    height: size(bpt, undefined, undefined, "2vw", "2vw"),
                  }}
                >
                  <FontAwesomeIcon
                    icon="angle-right"
                    style={{
                      width: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                      height: size(bpt, undefined, undefined, "1.5vw", "1.5vw"),
                      marginRight: size(
                        bpt,
                        undefined,
                        undefined,
                        "0.5vw",
                        "0.5vw"
                      ),
                    }}
                  />
                </Button>
              </div>
            </div> */}
          </div>

          {/* "See All" link */}
          {collectionId && (
            <div
              style={{
                fontSize: size(bpt, "3vw", "1.5vw", "1.4vw", "1.5vw"),
                marginBottom: size(bpt, "0.8vw", "0.2vw", "0.5vw", "0.6vw"),
              }}
              className="flex"
            >
              <Link to={`/store/collection/${collectionId}`} className="flex">
                <div>See All</div>
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="text-grey ml-1 h-full"
                />
              </Link>
            </div>
          )}

          {/* Scroll container */}
          <div
            ref={scrollContainer}
            onScroll={handleOnScroll}
            style={{
              marginLeft: -offsetWidth,
              marginRight: -offsetWidth,
              paddingLeft: offsetWidth,
              paddingRight: offsetWidth,
              columnGap: gutterWidth,
            }}
            className={`
              col-start-1
              col-span-full
              grid
              grid-flow-col
              auto-cols-min
              overflow-x-auto
              no-scrollbar
              ${isScrollSnapping ? "snap" : "no-snap"}
              snap-x snap-mandatory
              pt-2 tablet:pt-3
          `}
          >
            {/* Content Rail Items */}
            {children}

            {/* See More button for mobile */}
            {/* TODO: keeping for reference in the short term */}
            {/* {collectionId && (
              <ClickableContainer
                shadows="neumorphic-2-outer tablet:neumorphic-5-outer"
                shadowsPressed="neumorphic-2-inner tablet:neumorphic-5-inner"
                rounded="rounded-lg tablet:rounded-xl desktop:rounded-2.5xl"
                className="flex snap-start"
                classNameInner="
                justify-center items-center border-2 border-grey
                text-sm
                text-grey-dark"
                style={{
                  width: span3Width,
                  height: span3Width,
                  scrollMargin: offsetWidth,
                  scrollSnapMargin: offsetWidth,
                }}
                href={`/store/collection/${collectionId}`}
                onClick={() => {
                  navigate(`/store/collection/${collectionId}`)
                }}
              >
                See All
              </ClickableContainer>
            )} */}
          </div>
        </div>
      )}
    </>
  )
}

export default ContentRail
ContentRail.whyDidYouRender = false
