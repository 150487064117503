import { isSSR } from "helpers/ssrCheck"
import * as React from "react"
import { createContext, useContext } from "react"

//
// A context provider that stores collections
//
const CollectionsContext = createContext()

//
// Collection Items Provider
//
export const CollectionsProvider = ({ value, children }) => {
  return (
    <CollectionsContext.Provider value={value}>
      {children}
    </CollectionsContext.Provider>
  )
}

//
// Hook to use a collection item by id
//
export const useCollectionItem = (collectionId) => {
  const context = useContext(CollectionsContext)
  if (context === undefined) {
    if (isSSR) {
      // There is no Context available during SSR
      return
    } else {
      throw new Error(
        "useCollectionItem must be used within a CollectionsProvider"
      )
    }
  }

  return context.find((collection) => {
    return collection.id === collectionId
  })
}
