import { useLocation } from "@reach/router"
import { useEffect } from "react"

/**
 * Keep track of a page's scroll location so it can be restored when it
 * is loaded again.
 *
 * This is a simple replacement for the built-in Gatsby scroll restoration,
 * which does not work out the box for this product.
 */
export const useScrollToPrevious = () => {
  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Key for session storage
  const storageKey = `scroll@${location.pathname}`

  /**
   * Monitor the scroll location
   */
  useEffect(() => {
    // Scroll handler
    function onScroll() {
      // Write the scroll location to session storage
      sessionStorage.setItem(storageKey, window.scrollY)

      // Nuke the Gatsby scroll position from Session Storage
      setTimeout(() => {
        for (let index = 0; index < sessionStorage.length; index++) {
          const sessionStorageKey = sessionStorage.key(index)
          if (sessionStorageKey.startsWith("@@scroll")) {
            sessionStorage.removeItem(sessionStorageKey)
          }
        }
      }, 1)
    }

    // Get an existing scroll position from session storage
    const existingScrollPosition = sessionStorage.getItem(storageKey)

    if (existingScrollPosition > 0) {
      setTimeout(() => {
        window.scrollTo({
          top: existingScrollPosition,
          behavior: "smooth",
        })
      }, 500)
    }

    // Add scroll event listener
    window.addEventListener("scroll", onScroll, { passive: true })

    // Clean up scroll listener
    return function cleanup() {
      window.removeEventListener("scroll", onScroll)
    }
  }, [storageKey])
}
