import BreakpointContext from "context/breakpointContext"
import { useGridDimensions } from "context/gridDimensionsContext"
import { useSelectedItemContext } from "context/selectedItemContext"
import { SwiperProvider } from "context/swiperContext"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import React, { Children, useContext, useEffect, useRef, useState } from "react"
import "styles/carouselBanner.scss"
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Virtual, Autoplay])

//
// Carousel Banner for the home page.
//
const CarouselBanner = ({ className, children }) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Media queries
  const { isMobile } = useMediaQueries()

  // Grid dimensions from context
  const { offsetWidth } = useGridDimensions()

  // Swiper instance for updates
  const [swiper, setSwiper] = useState(undefined)

  // Capture the Swiper instance
  const captureSwiperInstance = (instance) => {
    setSwiper(instance)
  }

  // Pagination container reference
  const pagination = useRef(null)

  // Get the selected item from the context
  const { selectedItem } = useSelectedItemContext()

  // Update swiper to make sure slides are the correct width
  useEffect(() => {
    if (swiper) {
      swiper.update()
      swiper.params.pagination.el = pagination.current
      swiper.pagination.update()
    }
  })

  // Prevent autoplay when an item has been selected, to minimise distraction
  // behind a content card.
  useEffect(() => {
    if (swiper) {
      if (selectedItem) {
        stopAutoplay()
      } else {
        startAutoplay()
      }
    }
  })

  //
  // Start autoplaying slides, if there is
  // no item currently selected!
  //
  const startAutoplay = () => {
    if (swiper && !selectedItem) {
      swiper.autoplay.start()
    }
  }

  //
  // Stop autoplaying slides
  //
  const stopAutoplay = () => {
    if (swiper) {
      swiper.autoplay.stop()
    }
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        onMouseEnter={stopAutoplay}
        onMouseOver={stopAutoplay}
        onFocus={stopAutoplay}
        onMouseLeave={startAutoplay}
        onMouseOut={startAutoplay}
        onBlur={startAutoplay}
        role="banner"
        style={{
          marginLeft: offsetWidth ? -offsetWidth : 0,
          marginRight: offsetWidth ? -offsetWidth : 0,
          marginTop: size(bpt, "-2vw", "-1vw", "-1vw", "-1vw"),
          marginBottom: size(bpt, undefined, "-1.5vw", "-1.5vw", "-1.5vw"),
          minHeight: size(bpt, "145vw", "44vw", "43.5vw", "44vw"),
        }}
        className={`relative ${className}`}
      >
        <SwiperProvider value={swiper}>
          <Swiper
            loop={true}
            autoplay={{
              delay: 6000,
            }}
            slidesPerView={1}
            pagination={{
              bulletElement: "div",
              el: pagination.current ? pagination.current : undefined,
              clickable: true,
            }}
            onSwiper={captureSwiperInstance}
            onInit={(swiper) => {
              swiper.params.pagination.el = pagination.current
              swiper.pagination.update()
            }}
            touchStartPreventDefault={false} // Don't eat mouseDown events!
            className="h-full"
          >
            {Children.map(children, (slide, index) => {
              return (
                <SwiperSlide key={slide} virtualIndex={index}>
                  {slide}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperProvider>
      </div>

      {/* Pagination */}
      <div
        style={{
          left: offsetWidth,
          right: offsetWidth,
          top: size(bpt, "98vw"),
          marginTop: size(bpt, undefined, "4vw", "4vw", "3.3vw"),
        }}
        className={`
          col-start-1 
          col-span-full 
          justify-center
          flex
          ${isMobile ? "absolute" : ""}
        `}
      >
        <div ref={pagination} />
      </div>
    </>
  )
}

export default CarouselBanner
CarouselBanner.whyDidYouRender = false
