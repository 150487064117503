import ClickableContainer from "components/utils/clickableContainer"
import RemoteImage from "components/utils/remoteImage"
import BreakpointContext from "context/breakpointContext"
import { useContentItem } from "context/contentItemsContext"
import {
  SELECTED_ITEM,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { size } from "helpers/responsiveSize"
import PropTypes from "prop-types"
import React, { memo, useContext } from "react"

const TopItemsBoxItem = memo(({ index, contentRef }) => {
  // Grab the content item from the context
  const contentItem = useContentItem(contentRef)

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Set selected item using dispatcher from context
  const dispatchSelectedItem = useSelectedItemDispatchContext()

  return (
    <ClickableContainer
      shadows="neumorphic-4-outer tablet:neumorphic-5-outer"
      shadowsPressed="neumorphic-3-inner tablet:neumorphic-4-inner"
      rounded="rounded-lg"
      layout="grid"
      styleInner={{
        gridTemplateColumns: "1fr auto",
      }}
      className="col-span-6 overflow-hidden"
      onClick={() => {
        dispatchSelectedItem({
          type: SELECTED_ITEM,
          payload: contentItem,
        })
      }}
    >
      {/* Metadata */}
      <div
        className="flex flex-row overflow-hidden"
        style={{
          padding: size(bpt, "2vw", "0.55vw", "0.6vw", "0.55vw"),
        }}
      >
        {/* Number */}
        <div
          style={{
            fontSize: size(bpt, "6vw", "3vw", "3vw", "3vw"),
            padding: size(bpt, "1vw", "0.5vw", "0.5vw", "0.5vw"),
          }}
          className="
          font-bold
          leading-snug desktop:leading-none"
        >
          {index}
        </div>

        {/* Title & Author */}
        <div
          style={{
            fontSize: size(bpt, "4vw", "1.5vw", "1.5vw", "1.5vw"),
            paddingTop: size(bpt, "1vw", "0.6vw", "0.6vw", "0.2vw"),
            paddingLeft: size(bpt, "1vw", "0.6vw", "0.6vw", "0.2vw"),
          }}
          className="flex flex-col overflow-hidden"
        >
          {contentItem && contentItem.title && (
            <div
              className="
                tablet:font-bold
                tracking-wider
                truncate"
            >
              {contentItem.title}
            </div>
          )}
          {contentItem && contentItem.author && (
            <div
              className="
            font-light tablet:font-normal
            leading-tight
            tracking-tight"
            >
              {contentItem.author}
            </div>
          )}
        </div>
      </div>

      {/* Cover Art */}
      <div
        style={{
          width: size(bpt, "17.6vw", "7.2vw", "7.2vw", "7.2vw"),
        }}
      >
        {contentItem && contentItem.artwork && (
          <RemoteImage
            imagePath={contentItem.artwork}
            mobileImageSize={56}
            tabletImageSize={54}
            laptopImageSize={72}
            desktopImageSize={102}
            aspectRatio="1:1"
            className="h-auto w-full"
          />
        )}
      </div>
    </ClickableContainer>
  )
})

TopItemsBoxItem.propTypes = {
  index: PropTypes.number.isRequired,
  contentRef: PropTypes.string.isRequired,
}

export default TopItemsBoxItem
TopItemsBoxItem.whyDidYouRender = true
