import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import RadioIcon from "components/icons/contentTypes/stationIcon"
import ClickableContainer from "components/utils/clickableContainer"
import RemoteImage from "components/utils/remoteImage"
import BreakpointContext from "context/breakpointContext"
import { useContentItem } from "context/contentItemsContext"
import { useGridDimensions } from "context/gridDimensionsContext"
import {
  SELECTED_ITEM,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { ContentType, getColourForContentItem } from "helpers/contentTypes"
import { size } from "helpers/responsiveSize"
import { useCallToAction } from "hooks/useCallToAction"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useStoreLink } from "hooks/useStoreLink"
import PropTypes from "prop-types"
import React, { memo, useContext } from "react"

//
// An item of content in a Content Rail.
//
const ContentRailItem = memo(({ contentRef }) => {
  // Mount state
  const isMounted = useMountOnLoad()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Grab the content item from the context
  const contentItem = useContentItem(contentRef)

  // Get CTA detail
  const { isLoaded: isCTALoaded, text: ctaText } = useCallToAction(contentItem)

  // Store link for item
  const storeLink = useStoreLink(contentRef)

  // Set selected item using dispatcher from context
  const dispatchSelectedItem = useSelectedItemDispatchContext()

  // Grid dimensions from context
  const { offsetWidth, span3Width } = useGridDimensions()

  return (
    <>
      {/* Render an invisible item for SEO during SSR?  Or better yet, plumb the data through from GraphQL */}
      {/* TODO: Need to plumb the data in from the GraphQL query so we can embed it for SEO! */}

      {/* Work around SSR rehydration issues! */}
      {isMounted && (
        <div className="flex flex-col">
          <ClickableContainer
            shadows="neumorphic-2-outer tablet:neumorphic-5-outer"
            shadowsPressed="neumorphic-2-inner tablet:neumorphic-5-inner"
            rounded="rounded-lg tablet:rounded-xl desktop:rounded-2.5xl"
            style={{
              width: span3Width,
              height: span3Width,
              scrollMargin: offsetWidth,
              scrollSnapMargin: offsetWidth,
            }}
            className="flex snap-start"
            href={storeLink}
            onClick={() => {
              dispatchSelectedItem({
                type: SELECTED_ITEM,
                payload: contentItem,
              })
            }}
          >
            {contentItem && contentItem.artwork && (
              <RemoteImage
                imagePath={contentItem.artwork}
                mobileImageSize={150}
                tabletImageSize={200}
                laptopImageSize={260}
                desktopImageSize={290}
                className="
                  w-full h-full
                  rounded-lg tablet:rounded-xl desktop:rounded-2.5xl"
              />
            )}
          </ClickableContainer>

          {/* Content type & Price */}
          <div
            style={{
              marginTop: size(bpt, "1.5vw", "1.2vw", "0.9vw", "0.8vw"),
              marginLeft: size(bpt, "0.6vw", "0.4vw", "0.4vw", "0.4vw"),
              marginRight: size(bpt, "0.6vw", "0.4vw", "0.4vw", "0.4vw"),
            }}
            className="flex flex-row justify-between"
          >
            {/* Content type */}
            <div className="flex flex-row items-center">
              {/* Icon */}
              <div
                style={{
                  width: size(bpt, "2.6vw", "1.2vw", "1.1vw", "1.1vw"),
                  height: size(bpt, "2.3vw", "1.2vw", "1.1vw", "1.1vw"),
                }}
              >
                {contentItem?.type === ContentType.AUDIOBOOK && (
                  <AudiobookIcon alignment="right" />
                )}
                {contentItem?.type === ContentType.PODCAST && (
                  <PodcastIcon alignment="right" />
                )}
                {contentItem?.type === ContentType.STATION && (
                  <RadioIcon alignment="right" />
                )}
              </div>
              {/* Label */}
              <span
                style={{
                  fontSize: size(bpt, "2.4vw", "1.1vw", "1vw", "1vw"),
                  marginTop: size(bpt, "0.1vw", "0.05vw", "0.05vw", "0.05vw"),
                  marginLeft: size(bpt, "0.8vw", "0.4vw", "0.3vw", "0.3vw"),
                }}
                className="capitalize"
              >
                {contentItem?.type}
              </span>
            </div>
            {/* CTA text */}
            {isCTALoaded && ctaText && (
              <div
                style={{
                  fontSize: size(bpt, "1.7vw", "0.8vw", "0.7vw", "0.7vw"),
                  paddingTop: "0.1vw",
                  paddingLeft: size(bpt, "1vw", "0.5vw", "0.5vw", "0.5vw"),
                  paddingRight: size(bpt, "1vw", "0.5vw", "0.5vw", "0.5vw"),
                }}
                className={`
                  bg-${getColourForContentItem(contentItem)}
                  flex
                  text-white
                  rounded-full
                  self-center
                  uppercase
                  font-semibold
                  tracking-wider
                `}
              >
                {ctaText}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
})

ContentRailItem.propTypes = {
  contentRef: PropTypes.string.isRequired,
}

export default ContentRailItem
ContentRailItem.whyDidYouRender = false
