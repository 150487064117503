import { useMediaQueries } from "hooks/useMediaQueries"
import PropTypes from "prop-types"
import React, { memo } from "react"
import CarouselBannerSlideHorizontal from "./carouselBannerSlideHorizontal"
import CarouselBannerSlideVertical from "./carouselBannerSlideVertical"

//
// Carousel Banner Slide for the Store.
//
const CarouselBannerSlide = memo(({ title, subtitle, contentRef }) => {
  // Media queries for React
  const { isMobile, isTablet, isLaptop, isDesktop } = useMediaQueries()

  return (
    <>
      {isMobile && (
        <CarouselBannerSlideVertical
          title={title}
          subtitle={subtitle}
          contentRef={contentRef}
        />
      )}
      {(isTablet || isLaptop || isDesktop) && (
        <CarouselBannerSlideHorizontal
          title={title}
          subtitle={subtitle}
          contentRef={contentRef}
        />
      )}
    </>
  )
})

CarouselBannerSlide.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  contentRef: PropTypes.string.isRequired,
}

export default CarouselBannerSlide
CarouselBannerSlide.whyDidYouRender = true
