import { useLocation } from "@reach/router"
import CarouselBanner from "components/carouselBanner/carouselBanner"
import CarouselBannerSlide from "components/carouselBanner/carouselBannerSlide"
import CollectionContainer from "components/collectionContainer"
import ContentRail from "components/contentRail/contentRail"
import ContentRailItem from "components/contentRail/contentRailItem"
import SectionHeader from "components/sectionHeader"
import TopItemsBox from "components/topItemsBox/topItemsBox"
import TopItemsBoxItem from "components/topItemsBox/topItemsBoxItem"
import Metadata from "components/utils/metadata/metadata"
import { CollectionsProvider } from "context/collectionsContext"
import { ContentItemsProvider } from "context/contentItemsContext"
import { Screen, useSetScreen } from "context/screenContext"
import { useContentItemsMap } from "hooks/useContentItemsMap"
import { useFooter } from "hooks/useFooter"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useQueryForUKStoreFront } from "hooks/useQueryForUKStoreFront"
import { useScrollToPrevious } from "hooks/useScrollToPrevious"
import { useTabBar } from "hooks/useTabBAr"
import React, { useMemo } from "react"

const StoreFeaturedUK = () => {
  // Set the screen
  useSetScreen(Screen.STORE)

  // Query for data for content item components
  const data = useQueryForUKStoreFront()

  // Extract collections from injected data
  const collections = useMemo(() => {
    return data?.superfonik?.collections
  }, [data])

  // Create a map of content items for the content items provider
  const contentItemsMap = useContentItemsMap(data?.superfonik?.selection)

  // Mount state
  const isMounted = useMountOnLoad()

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Only show footer when mounted
  useFooter(isMounted)

  // Use a Tab Bar
  useTabBar(true)

  // Scroll to the previous location for this page
  useScrollToPrevious()

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata
        title="Featured"
        pathname={location.pathname}
        useStrapLine={true}
      />

      <ContentItemsProvider contentItemsMap={contentItemsMap}>
        {/* GO-LIVE BANNER */}
        <CarouselBanner className="col-span-full">
          {/* Matilda */}
          <CarouselBannerSlide
            title="She's little but don't underestimate her!"
            subtitle="Matilda said: &ldquo;Never do anything by halves if you want to get
            away with it.&rdquo;"
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349213"
          />
          {/* The Secret Explorers and the Lost Whales */}
          <CarouselBannerSlide
            title="Go on an underwater adventure!"
            subtitle="Learn about humpback whales and fascinating deep-sea creatures in this action-packed adventure story."
            contentRef="audiobooks/dk-uk/titles/ISBN-9780241491720"
          />
          {/* TODO: HC UK */}
          {/* The Danger Gang */}
          <CarouselBannerSlide
            title="Watch out for bolts of green lightning"
            subtitle="The kids who live on Franky's street have become a little odd. A little unusual. A little... magical."
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241408438"
          />
          {/* TODO: HC UK */}
          {/* Kay's Anatomy */}
          <CarouselBannerSlide
            title="Brilliant and revolting"
            subtitle="A Complete (and Completely Disgusting) Guide to the entire Human Body."
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241473658"
          />
          {/* Percy Jackson and the Lightning Thief (Book 1) */}
          <CarouselBannerSlide
            title="Percy is half boy, half god, ALL hero."
            subtitle="I was accidentally vaporized my maths teacher. And now that Zeus thinks I've stolen his lightning bolt..."
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141961941"
          />
          <CarouselBannerSlide
            title="Wind down with Peppa Pig &amp; friends"
            subtitle="A collection of ten snoozy stories to send your little one gently off to sleep"
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241299050"
          />
          {/* Ladybird Tales: The Complete Collection */}
          <CarouselBannerSlide
            title="Beloved classics"
            subtitle="A perfect introduction to the classics for young children, told in a warm and accessible style."
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241310250"
          />
          {/* The Creakers */}
          <CarouselBannerSlide
            title="The kids are running wild!"
            subtitle="What would you do if you woke up to find all the grown-ups had disappeared?"
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241407981"
          />
          {/* The Hatmakers */}
          <CarouselBannerSlide
            title="Who is making mischief?"
            subtitle="Cordelia is determined to find her father Prospero, who is lost at sea while collecting magical ingredients."
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241447246"
          />
          {/* Just William: A BBC Radio Collection */}
          <CarouselBannerSlide
            title="A famous 11 year-old and his Outlaws"
            subtitle="William is perpetually scruffy, mud-stained and mischievous!"
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781785296406"
          />
        </CarouselBanner>

        <SectionHeader text="Best selling audiobooks" />

        <TopItemsBox>
          {/* Matilda */}
          <TopItemsBoxItem
            index={1}
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349213"
          />
          {/* Percy Jackson and the Lightning Thief (Book 1) */}
          <TopItemsBoxItem
            index={2}
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141961941"
          />
          {/* Science, by DK */}
          <TopItemsBoxItem
            index={3}
            contentRef="audiobooks/dk-uk/titles/ISBN-9780241522295"
          />
          {/* Charlie and the Chocolate Factory */}
          <TopItemsBoxItem
            index={4}
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349060"
          />
          {/* The Secret Explorers and the Jurassic Rescue */}
          <TopItemsBoxItem
            index={5}
            contentRef="audiobooks/dk-uk/titles/ISBN-9780241491744"
          />
          {/* The Danger Gang */}
          <TopItemsBoxItem
            index={6}
            contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241408438"
          />
        </TopItemsBox>

        {/* New releases */}
        <ContentRail title="New releases">
          {/* 1. Gangsta Granny */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008524111" />

          {/* 2. The Good Turn */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241581124" />

          {/* 3. Rainbow Grey: Eye of the Storm */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008507381" />

          {/* 4. Wilder than Midnight */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241556610" />

          {/* 5. History, by DK */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241572528" />

          {/* 6. Into the Sideways World */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008333867" />

          {/* 7. Princess Olivia Investigates: The Wrong Weather */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241601631" />

          {/* 8. Oona */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008508494" />

          {/* 9. Norse Myths */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241543207" />

          {/* 10. The Little Prince */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241574669" />

          {/* 11. BBC Classics Children’s Library*/}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781529142549" />

          {/* 12. The Summer I Robbed A Bank */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241478806" />
        </ContentRail>

        <SectionHeader text="Bag a big bear-hug" />

        {/* Paddington & Pooh */}
        <CollectionsProvider value={collections}>
          <CollectionContainer
            collectionId="paddington-bear-uk"
            className="
            mb-5 tablet:mb-0
            col-start-1 tablet:col-start-1
            col-span-full tablet:col-span-6"
          />
          <CollectionContainer
            collectionId="pooh-bear-uk"
            className="
            col-start-1 tablet:col-start-7
            col-span-full tablet:col-span-6"
          />
        </CollectionsProvider>

        <ContentRail title="Exercise your brain">
          {/* 1. The Bacteria Book */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241508336" />

          {/* 2. Tumble Science Podcast */}
          <ContentRailItem contentRef="podcasts/tumble-science-podcast-for-kids" />

          {/* 3. Volcanoes: Ladybird Audio Adventures */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241478042" />

          {/* 4. Brains On! Science podcast for kids */}
          <ContentRailItem contentRef="podcasts/brains-on-science-podcast-for-kids" />

          {/* 5. Ear snacks podcast */}
          <ContentRailItem contentRef="podcasts/ear-snacks" />

          {/* 6. A really short history of nearly everything */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241473610" />

          {/* 7. But Why podcast */}
          <ContentRailItem contentRef="podcasts/but-why-a-podcast-for-curious-kids" />

          {/* 8. What If World podcast */}
          <ContentRailItem contentRef="podcasts/what-if-world-stories-for-kids" />

          {/* 9. Life Cycles */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241508374" />

          {/* 10. Stuff you should know podcast */}
          <ContentRailItem contentRef="podcasts/stuff-you-should-know" />

          {/* 11. Who smarted podcast */}
          <ContentRailItem contentRef="podcasts/who-smarted" />

          {/* 12. George's Secret Key to the Universe */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781407041780" />
        </ContentRail>

        <ContentRail title="All about animals">
          {/* 1. The The Jungle Book */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241346501" />

          {/* 2. Sharks */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241571002" />

          {/* 3. Einstein the Penguin */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008475987" />

          {/* 4. The Enormous Crocodile */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349657" />

          {/* 5. Narwhal and Jelly Audio Bundle */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008508296" />

          {/* 6. Black beauty */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241452271" />

          {/* 7. The Birthday Duck */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008442538" />

          {/* 8. Beatrix Potter: The Complete Tales */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780723269588" />

          {/* 9. Blue Dog */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781473547117" />

          {/* 10. The Racehorse Who Wouldn't Gallop */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141373294" />

          {/* 11. Snow Foal */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008505400" />

          {/* 12. Puss In Boots */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781787620964" />
        </ContentRail>

        {/* People & ideas */}
        <SectionHeader text="People &amp; ideas" />
        <CollectionsProvider value={collections}>
          <CollectionContainer
            collectionId="dk-life-stories-uk"
            className="
            mb-5 tablet:mb-0
            col-start-1 tablet:col-start-1
            col-span-full tablet:col-span-6"
          />
          <CollectionContainer
            collectionId="dk-heads-up-uk"
            className="
            col-start-1 tablet:col-start-7
            col-span-full tablet:col-span-6"
          />
        </CollectionsProvider>

        <ContentRail title="Tickle your funny-bone">
          {/* 1. Diary of a Wimpy Kid */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241354841" />

          {/* 2. Ratburger */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780007468171" />

          {/* 3. Dragons at Crumbling Castle */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781448196029" />

          {/* 4. The Twits */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349527" />

          {/* 5. The Caravan at the Edge of Doom */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008496449" />

          {/* 6. Llama Out Loud! */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008508227" />

          {/* 7. Dog Diaries: Curse of the Mystery Mutt */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781473576339" />

          {/* 8. The Dinosaur that Pooped a Planet! */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9781448197019" />

          {/* 9. Radio Boy and the Revenge of Grandad */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008200619" />

          {/* 10. Funnybones: The Collection */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141385372" />

          {/* 11. Hubert Horatio: A Very Fishy Tale: World Book Day 2019 */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008340537" />

          {/* 12. Castle in the Air */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008501075" />

          {/* 13. Eddie Albert and the Amazing Animal Gang: The Amsterdam... */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008446826" />
        </ContentRail>

        <SectionHeader text="Top podcasts" />

        <TopItemsBox>
          <TopItemsBoxItem
            index={1}
            contentRef="podcasts/stories-podcast-a-bedtime-show-for-kids-of-all-ages"
          />
          <TopItemsBoxItem
            index={2}
            contentRef="podcasts/but-why-a-podcast-for-curious-kids"
          />
          <TopItemsBoxItem
            index={3}
            contentRef="podcasts/good-night-stories-for-rebel-girls"
          />
          <TopItemsBoxItem index={4} contentRef="podcasts/circle-round" />
          <TopItemsBoxItem index={5} contentRef="podcasts/story-pirates" />
          <TopItemsBoxItem
            index={6}
            contentRef="podcasts/brains-on-science-podcast-for-kids"
          />
        </TopItemsBox>

        <ContentRail title="Myths, legends &amp; fables">
          {/* 1. Greek Myths */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241474099" />

          {/* 2. Live from Mount Olympus (podcast)  */}
          <ContentRailItem contentRef="podcasts/live-from-mount-olympus" />

          {/* 3. Moonchild: City of the Sun */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780008496425" />

          {/* 4. The Lost Hero (Heroes of Olympus Book 1) */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141355788" />

          {/* 5. Greeking Out from National Geographic Kids */}
          <ContentRailItem contentRef="podcasts/greeking-out-from-national-geographic-kids" />

          {/* 6. The Outsiders (Gods and Warriors Book 1) */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141345789" />

          {/* 7. Children's Book of Mythical Beasts and Magical Monsters */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241465226" />

          {/* 8. Tales of the Greek Heroes */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141932057" />

          {/* 9. Adventures in Time: Alexander the Great */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141998770" />

          {/* 10. Aesop’s Fables */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780007376339" />

          {/* 11. Gladiator Clash */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780007558865" />

          {/* 12. Myths, Legends & Sacred Stories */}
          <ContentRailItem contentRef="audiobooks/dk-uk/titles/ISBN-9780241475737" />
        </ContentRail>

        <SectionHeader text="All-time favourites" />

        <CollectionsProvider value={collections}>
          <CollectionContainer
            collectionId="roald-dahl-uk"
            className="
            mb-5 tablet:mb-0
            col-start-1 tablet:col-start-1
            col-span-full tablet:col-span-6"
          />
          <CollectionContainer
            collectionId="chronicles-of-narnia-uk"
            className="
            col-start-1 tablet:col-start-7
            col-span-full tablet:col-span-6"
          />
        </CollectionsProvider>

        <SectionHeader text="Top radio stations" />

        <TopItemsBox>
          <TopItemsBoxItem index={1} contentRef="stations/rock-the-cradle" />
          <TopItemsBoxItem index={2} contentRef="stations/fun-kids" />
          <TopItemsBoxItem index={3} contentRef="stations/kidjam-radio" />
          <TopItemsBoxItem index={4} contentRef="stations/fun-kids-party" />
          <TopItemsBoxItem index={5} contentRef="stations/abc-kids-listen" />
          <TopItemsBoxItem index={6} contentRef="stations/kids-fm-radio" />
        </TopItemsBox>

        <SectionHeader text="High adventure" />

        <CollectionsProvider value={collections}>
          <CollectionContainer
            collectionId="philip-pullman-uk"
            className="
            mb-5 tablet:mb-0
            col-start-1 tablet:col-start-1
            col-span-full tablet:col-span-6"
          />
          <CollectionContainer
            collectionId="rick-riordon-uk"
            className="
            col-start-1 tablet:col-start-7
            col-span-full tablet:col-span-6"
          />
        </CollectionsProvider>

        <ContentRail title="Sleepy time">
          {/* 1. Ladybird Sleepy Tales */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241424339" />

          {/* 2. Puffin Sleepy Tales */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241424353" />

          {/* 3. Dreamful Bedtime Stories (podcast) */}
          <ContentRailItem contentRef="podcasts/dreamful-bedtime-stories" />

          {/* 4. Bedtime History: Inspirational Stories for Kids and Families (podcast) */}
          <ContentRailItem contentRef="podcasts/bedtime-history" />

          {/* 5. Ladybird Lullabies: Songs for Bedtime */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241478141" />

          {/* 6. Ladybird Favourite Fairy Tales */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241335062" />

          {/* 7. Stories Podcast: A Bedtime Show for Kids of All Ages (podcast) */}
          <ContentRailItem contentRef="podcasts/stories-podcast-a-bedtime-show-for-kids-of-all-ages" />

          {/* 8. Peppa Pig: Bedtime Stories */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241299050" />

          {/* 9. Ten Minutes to Bed: Little Dragon */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241523445" />

          {/* 10. Kids meditation & sleep stories podcast */}
          <ContentRailItem contentRef="podcasts/kids-meditation-sleep-stories" />

          {/* 11. The Little Elephant Who Wants to Fall Asleep */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241291221" />

          {/* 12. Sleepytime Lullabies */}
          <ContentRailItem contentRef="audiobooks/harpercollins-uk/titles/ISBN-9780007451098" />

          {/* 13. Goodnight Spaceman */}
          <ContentRailItem contentRef="audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141378633" />

          {/* 14. Sleep Tight Relax - Helping kids become calm at bedtime */}
          <ContentRailItem contentRef="podcasts/sleep-tight-relax-helping-kids-become-calm-at-bedtime" />

          {/* 15. Sleep Tight Stories - Bedtime Stories for Kids */}
          <ContentRailItem contentRef="podcasts/sleep-tight-stories-bedtime-stories-for-kids" />
        </ContentRail>
      </ContentItemsProvider>
    </>
  )
}

export default StoreFeaturedUK
StoreFeaturedUK.whyDidYouRender = false
