import ClickableContainer from "components/utils/clickableContainer"
import BreakpointContext from "context/breakpointContext"
import { useCollectionItem } from "context/collectionsContext"
import { useCountries } from "context/countryContext"
import { navigate } from "gatsby"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import PropTypes from "prop-types"
import React, { memo, useContext, useEffect, useRef, useState } from "react"
import routes from "services/routes"
import RemoteImage from "./utils/remoteImage"

const CollectionContainer = memo((props) => {
  // Props
  const { collectionId, className } = props

  // Media queries for React
  const { isMobile } = useMediaQueries()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // A reference to the container div for maintaining aspect ratio
  const container = useRef(null)

  // The current width of the container
  const [containerWidth, setContainerWidth] = useState(0)

  // Grab the collection from the context
  const collection = useCollectionItem(collectionId)

  // Sotre country from context
  const { storeURL } = useCountries()

  useEffect(() => {
    // // Make sure we can render the collection
    // if (collection.items?.length !== 1 && collection.items?.length !>= 3) {
    //   throw Error(
    //     "A feature box can currently only render a single items or a collection of 3 items!"
    //   )
    // }
    // Use a Resize Observer to measure width of container
    // so that height can be determined dynamically
    // in order to maintain aspect ratio
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width)
      }
    })
    if (container.current) {
      observer.observe(container.current)
    }

    // Cleanup function to stop observation
    return function cleanup() {
      observer.disconnect()
    }
  })

  //
  // Render an item image with the specified alignment
  //
  const itemImage = (key, contentItem, alignment = "center") => {
    if (!contentItem) {
      // The content item may not be returned from the
      // map of content items before a render has occured,
      // so return an empty placeholder in that case...
      return <div key={key} />
    }
    return (
      <RemoteImage
        key={key}
        imagePath={contentItem.artwork}
        mobileImageSize={170}
        tabletImageSize={135}
        laptopImageSize={180}
        desktopImageSize={255}
        aspectRatio="1:1"
        style={{
          height: size(bpt, "53vw", "17.3vw", "17vw", "17.5vw"),
        }}
        className={`
          select-none
          justify-self-center
          rounded-lg laptop:rounded-xl
          neumorphic-2-color tablet:neumorphic-3-color laptop:neumorphic-4-color
          transform-gpu
          ${alignment === "center" ? "z-10" : "absolute -translate-y-1"}
          ${
            alignment === "left"
              ? "scale-65 left-0 origin-bottom-left tablet:translate-x-5 laptop:translate-x-7 desktop:translate-x-10"
              : ""
          }
          ${
            alignment === "right"
              ? "scale-50 right-0 origin-bottom-right tablet:-translate-x-8 laptop:-translate-x-11 desktop:-translate-x-15"
              : ""
          }
        `}
      />
    )
  }

  return (
    <>
      {collection?.items && bpt && (
        <ClickableContainer
          ref={container}
          shadows="neumorphic-4-inner tablet:neumorphic-5-inner"
          shadowsPressed="neumorphic-4-inner tablet:neumorphic-5-inner"
          rounded="rounded-3xl"
          layout="grid grid-flow-row"
          style={{
            height: isMobile ? containerWidth * 0.64 : containerWidth * 0.73,
          }}
          styleInner={{
            padding: isMobile ? "4vw" : "2.5vw",
            rowGap: "3vw",
            gridTemplateRows: isMobile ? "12vw auto" : "5vw auto",
          }}
          className={`
            overflow-hidden
            ${className}
          `}
          onClick={() => {
            navigate(routes.STORE_COLLECTION(storeURL, collection.slug))
          }}
        >
          {/* Title */}
          <p
            style={{
              fontSize: isMobile ? "4vw" : "2vw",
              lineHeight: "2.5vw",
            }}
            className={`
              text-grey-darker
              text-center
              self-end
              font-bold
              leading-none tablet:leading-tight
              tracking-wider tablet:tracking-wide
            `}
          >
            {collection.title}
          </p>

          {/* Single item */}
          {collection?.items?.length === 1 && itemImage(collection.items[0])}

          {/* Three items */}
          {collection?.items?.length === 3 && (
            <div className="flex relative justify-center">
              {[...Array(3)].map((_, index) =>
                itemImage(
                  index,
                  collection.items[index],
                  index === 0 ? "left" : index === 1 ? "center" : "right"
                )
              )}
            </div>
          )}
        </ClickableContainer>
      )}
    </>
  )
})

CollectionContainer.propTypes = {
  collectionId: PropTypes.string.isRequired,
}

export default CollectionContainer
CollectionContainer.whyDidYouRender = true
