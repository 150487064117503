import Button from "components/controls/button"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import StationIcon from "components/icons/contentTypes/stationIcon"
import AspectRatioBox from "components/utils/aspectRatioBox"
import ClickableContainer from "components/utils/clickableContainer"
import RemoteImage from "components/utils/remoteImage"
import BreakpointContext from "context/breakpointContext"
import { useContentItem } from "context/contentItemsContext"
import {
  SELECTED_ITEM,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { ContentType } from "helpers/contentTypes"
import { h2Responsive } from "helpers/headingSize"
import { size } from "helpers/responsiveSize"
import { useCallToAction } from "hooks/useCallToAction"
import { useStoreLink } from "hooks/useStoreLink"
import GridLayout from "layouts/gridLayout"
import PropTypes from "prop-types"
import React, { memo, useContext } from "react"

//
// Store carousel banner slides
//
const CarouselBannerSlideHorizontal = memo(
  ({ title, subtitle, contentRef }) => {
    // The current breakpoint
    const bpt = useContext(BreakpointContext)

    // Set selected item using dispatcher from context\
    const dispatchSelectedItem = useSelectedItemDispatchContext()

    // Grab the content item from the context
    const contentItem = useContentItem(contentRef)

    // Get CTA detail
    const { isLoaded: isCTALoaded, text: ctaText } =
      useCallToAction(contentItem)

    // Store link for item
    const storeLink = useStoreLink(contentRef)

    return (
      <>
        {bpt && (
          <GridLayout
            type="fluid"
            container={false}
            offsets={true}
            style={{
              paddingTop: size(bpt, undefined, "1vw", "1vw", "1vw"),
              paddingBottom: size(bpt, undefined, "1.5vw", "1.5vw", "1.5vw"),
            }}
          >
            {/* Metadata, badge, CTA, etc */}
            <div
              className="col-span-6 grid"
              style={{
                gridTemplateRows: "repeat(4, auto) 1fr",
                gap: "1.5vw",
                paddingLeft: size(bpt, undefined, "4vw", "4vw", "4vw"),
                paddingRight: size(bpt, undefined, "4vw", "4vw", "4vw"),
                paddingTop: size(bpt, undefined, "7vw", "7vw", "7vw"),
              }}
            >
              {/* Content type */}
              <div className="flex flex-row">
                {/* Content icon */}
                <div
                  style={{
                    width: size(bpt, undefined, "2.5vw", "2.5vw", "2.5vw"),
                    height: size(bpt, undefined, "2.5vw", "2.5vw", "2.5vw"),
                  }}
                  className="mr-2"
                >
                  {contentItem?.type === ContentType.AUDIOBOOK && (
                    <AudiobookIcon />
                  )}
                  {contentItem?.type === ContentType.PODCAST && <PodcastIcon />}
                  {contentItem?.type === ContentType.STATION && <StationIcon />}
                </div>
                {/* Content name */}
                <div
                  className="font-bold self-center"
                  style={{
                    fontSize: size(bpt, undefined, "1.6vw", "1.6vw", "1.6vw"),
                  }}
                >
                  {contentItem?.type === ContentType.AUDIOBOOK && (
                    <span className="text-blue">Audiobook</span>
                  )}
                  {contentItem?.type === ContentType.PODCAST && (
                    <span className="text-orange">Podcast</span>
                  )}
                  {contentItem?.type === ContentType.STATION && (
                    <span className="text-red">Station</span>
                  )}
                </div>
              </div>

              {/* Title */}
              <span
                className="font-bold leading-tight line-clamp-2"
                style={{
                  fontSize: h2Responsive(bpt),
                }}
              >
                {title}
              </span>

              {/* Subtitle */}
              <span
                className="font-normal leading-snug line-clamp-4"
                style={{
                  fontSize: size(bpt, undefined, "1.8vw", "1.8vw", "1.8vw"),
                }}
              >
                {subtitle}
              </span>

              {/* CTA button */}
              {ctaText && (
                <Button
                  text={ctaText}
                  type="primary"
                  loading={!isCTALoaded}
                  shadowHeight={size(bpt, undefined, 3, 4, 5)}
                  size={size(bpt, undefined, "xs", "md", "lg")}
                  style={{
                    height: size(bpt, undefined, "3vw", "3vw", "3vw"),
                    width: size(bpt, undefined, "12.5vw", "12.5vw", "12.5vw"),
                    marginTop: size(bpt, undefined, "2.5vw", "2.5vw", "2.5vw"),
                  }}
                  className="text-grey-light"
                  onClick={() => {
                    dispatchSelectedItem({
                      type: SELECTED_ITEM,
                      payload: contentItem,
                    })
                  }}
                />
              )}

              <div>{/* Spacer div */}</div>
            </div>

            {/* Artwork */}
            <ClickableContainer
              shadows="tablet:neumorphic-4-outer laptop:neumorphic-5-outer desktop:neumorphic-6-outer"
              shadowsPressed="tablet:neumorphic-4-inner laptop:neumorphic-5-inner desktop:neumorphic-6-inner"
              rounded="tablet:rounded-4xl desktop:rounded-5xl"
              className="col-span-6 flex overflow-hidden"
              href={storeLink}
              onClick={() => {
                dispatchSelectedItem({
                  type: SELECTED_ITEM,
                  payload: contentItem,
                })
              }}
            >
              <AspectRatioBox mobileRatio="100%" className="w-full">
                {contentItem?.artwork && (
                  <RemoteImage
                    imagePath={contentItem.artwork}
                    tabletImageSize={325}
                    laptopImageSize={430}
                    desktopImageSize={615}
                    aspectRatio="1:1"
                    className="w-full h-auto"
                  />
                )}
              </AspectRatioBox>
            </ClickableContainer>
          </GridLayout>
        )}
      </>
    )
  }
)

CarouselBannerSlideHorizontal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  contentRef: PropTypes.string.isRequired,
}

export default CarouselBannerSlideHorizontal
CarouselBannerSlideHorizontal.whyDidYouRender = true
