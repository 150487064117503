import BreakpointContext from "context/breakpointContext"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import GridLayout from "layouts/gridLayout"
import PropTypes from "prop-types"
import React, { Children, useContext } from "react"

const TopItemsBox = ({ children }) => {
  // The number of items
  const itemCount = Children.count(children)

  // Media queries for React
  const { isMobile } = useMediaQueries()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Calculate the number of rows
  const rows = isMobile
    ? `repeat(${itemCount}, minmax(0, 1fr)`
    : `repeat(${Math.ceil(itemCount / 2)}, minmax(0, 1fr))`

  return (
    <>
      {bpt && (
        <GridLayout
          type="fluid"
          container={false}
          offsets={false}
          styleInner={{
            gridTemplateRows: rows,
            gridRowGap: size(bpt, "3vw", "1.5vw", "1.5vw", "1.5vw"),
          }}
          className="
            col-start-1
            col-span-full"
          classNameInner={`
            grid-flow-col
          `}
        >
          {children}
        </GridLayout>
      )}
    </>
  )
}

TopItemsBox.propTypes = {
  children: PropTypes.array.isRequired,
}

export default TopItemsBox
TopItemsBox.whyDidYouRender = false
