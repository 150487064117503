import { graphql, useStaticQuery } from "gatsby"

//
// GraphQL query for featured items in the UK store
//
export const useQueryForUKStoreFront = () => {
  return useStaticQuery(graphql`
    fragment audiobookFields on Superfonik_Audiobook {
      type
      id
      ref
      slug
      isbn
      title
      description
      author
      author2
      narrator
      publisher
      artwork
      audience
      categories {
        category
        subcategory
      }
      ageFrom
      ageTo
      duration
      publisher
      publicationDate
      saleCurrency
      salePriceIncTax
      salePriceExTax
      supplier
    }
    fragment podcastFields on Superfonik_Podcast {
      type
      id
      ref
      title
      description
      style
      artwork
      website
    }
    fragment stationFields on Superfonik_Station {
      type
      id
      ref
      title
      description
      artwork
      website
      location
    }
    query FeaturedItemsUK {
      superfonik {
        #
        # Selection of individual content items
        #
        selection(
          storeCountry: "GB"
          paths: [
            #
            # Carousel Banner
            # ---------------
            #
            # Matilda
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349213"
            #
            # The Secret Explorers and the Lost Whales
            "audiobooks/dk-uk/titles/ISBN-9780241491720"
            #
            # Danger Gang
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241408438"
            #
            # Percy Jackson and the Lightning Thief (Book 1)
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141961941"
            #
            # Peppa Pig: Bedtime Stories
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241299050"
            #
            # Kay’s Anatomy
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241473658"
            #
            # The Creakers
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241407981"
            #
            # The Hatmakers
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241447246"
            #
            # Ladybird Classics: The Complete Audio Collection
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241310250"
            #
            # Just William: A BBC Radio Collection
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781785296406"
            #

            #
            # Free Audiobooks for little ones [DEFUNCT]
            # -------------------------------
            #
            # Funnybones: The Collection
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141385372"
            # Ladybird Sleepy Tales
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241424339"
            # Ladybird Tales: The Complete Audio Collection
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241310250"
            # Peppa Pig: Once Upon a Time
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141370873"
            # Beatrix Potter The Complete Tales
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241440803"
            # The Rabbit Who Wants to Fall Asleep
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241255179"
            # The Runaway Girls
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781787620667"
            # The Snowman and the Snowdog
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241346365"
            # The Worst Witch Strikes Again
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141355283"
            # Ladybird Classics: The Complete Audio Collection
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141372501"
            # The Little Elephant Who Wants to Fall Asleep
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241291221"
            # Topsy and Tim: The Complete Audio Collection
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141378237"
            #

            #
            # Free Audiobooks for ages 6 and up [DEFUNCT]
            # ---------------------------------
            #
            # The Secret Garden
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241332634"
            # Murder Most Unladylike
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141369785"
            # Kay’s Anatomy
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241473658"
            # Percy Jackson and the Greek Gods
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141365848"
            # Diary of a Wimpy Kid: The Last Straw (Book 3)
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241355756"
            # Percy Jackson and the Greek Heroes
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141365824"
            # Heidi
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241363003"
            # Arsenic For Tea
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141369815"
            # Rowley Jefferson's Awesome Friendly Spooky Stories
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241530733"
            # First Class Murder
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141369846"
            # Esio Trot
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349589"
            # The Railway Children
            # "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241346419"
            #

            #
            # Best sellers
            # -------------
            #
            # 1. Matilda
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349213"
            #
            # 2. Percy Jackson and the Lightning Thief (Book 1)
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141961941"
            #
            # 3. Science, by DK
            "audiobooks/dk-uk/titles/ISBN-9780241522295"
            #
            # 4. Charlie and the Chocolate Factory
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349060"
            #
            # 5. The Secret Explorers and the Jurassic Rescue
            "audiobooks/dk-uk/titles/ISBN-9780241491744"
            #
            # 6. The Danger Gang
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241408438"
            #

            #
            # New releases
            # ------------
            #
            # 1. Gangsta Granny
            "audiobooks/harpercollins-uk/titles/ISBN-9780008524111"
            #
            # 2. The Good Turn
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241581124"
            #
            # 3. Rainbow Grey: Eye of the Storm
            "audiobooks/harpercollins-uk/titles/ISBN-9780008507381"
            #
            # 4. Wilder than Midnight
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241556610"
            #
            # 5. History, by DK
            "audiobooks/dk-uk/titles/ISBN-9780241572528"
            #
            # 6. Into the Sideways World
            "audiobooks/harpercollins-uk/titles/ISBN-9780008333867"
            #
            # 7. Princess Olivia Investigates: The Wrong Weather
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241601631"
            #
            # 8. Oona
            "audiobooks/harpercollins-uk/titles/ISBN-9780008508494"
            #
            # 9. Norse Myths
            "audiobooks/dk-uk/titles/ISBN-9780241543207"
            #
            # 10. The Little Prince
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241574669"
            #
            # 11. BBC Classics: Children's Library
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781529142549"
            #
            # 12. The Summer I Robbed A Bank
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241478806"
            #

            #
            # Exercise your brain
            # -------------------
            #
            # 1. The Bacteria Book
            "audiobooks/dk-uk/titles/ISBN-9780241508336"
            #
            # 2. Tumble science podcats for kids
            "podcasts/tumble-science-podcast-for-kids"
            #
            # 3. Volcanoes: Ladybird Audio Adventures
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241478042"
            #
            # 4. Brains On!
            "podcasts/brains-on-science-podcast-for-kids"
            #
            # 5. Ear Snacks
            "podcasts/ear-snacks"
            #
            # 6. A really short history of nearly everything
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241473610"
            #
            # 7. Buy Why podcast
            "podcasts/but-why-a-podcast-for-curious-kids"
            #
            # 8. What if World
            "podcasts/what-if-world-stories-for-kids"
            #
            # 9. Life Cycles
            "audiobooks/dk-uk/titles/ISBN-9780241508374"
            #
            # 10. Stuff you should know
            "podcasts/stuff-you-should-know"
            #
            # 11. Who smarted?
            "podcasts/who-smarted"
            #
            # 12. George's Secret Key to the Universe
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781407041780"
            #

            #
            # All about Animals
            # -----------------
            #
            # 1. The The Jungle Book
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241346501"
            #
            # 2. Sharks
            "audiobooks/dk-uk/titles/ISBN-9780241571002"
            #
            # 3. Einstein the Penguin
            "audiobooks/harpercollins-uk/titles/ISBN-9780008475987"
            #
            # 4. The Enormous Crocodile
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349657"
            #
            # 5. Narwhal and Jelly Audio Bundle
            "audiobooks/harpercollins-uk/titles/ISBN-9780008508296"
            #
            # 6. Black Beauty
            "audiobooks/dk-uk/titles/ISBN-9780241452271"
            #
            # 7. The Birthday Duck
            "audiobooks/harpercollins-uk/titles/ISBN-9780008442538"
            #
            # 8. Beatrix Potter The Complete Tales
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780723269588"
            #
            # 9. Blue Dog
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781473547117"
            #
            # 10. The Racehorse Who Wouldn't Gallop
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141373294"
            #
            # 11. Snow Foal
            "audiobooks/harpercollins-uk/titles/ISBN-9780008505400"
            #
            # 12. Puss In Boots
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781787620964"
            #

            #
            # Tickle your funny-bone
            # ----------------------
            #
            # 1. Diary of a wimpy kid
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241354841"
            #
            # 2. Ratburger
            "audiobooks/harpercollins-uk/titles/ISBN-9780007468171"
            #
            # 3. Dragons at Crumbling Castle
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781448196029"
            #
            # 4. The Twits
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141349527"
            #
            # 5. The Caravan at the Edge of Doom
            "audiobooks/harpercollins-uk/titles/ISBN-9780008496449"
            #
            # 6. Llama Out Loud!
            "audiobooks/harpercollins-uk/titles/ISBN-9780008508227"
            #
            # 7. Dog Diaries: Curse of the Mystery Mutt
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781473576339"
            #
            # 8. The Dinosaur that Pooped a Planet!
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9781448197019"
            #
            # 9. Radio Boy and the Revenge of Grandad
            "audiobooks/harpercollins-uk/titles/ISBN-9780008200619"
            #
            # 10. Funnybones: The collection
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141385372"
            #
            # 11. Hubert Horatio: A Very Fishy Tale: World Book Day 2019
            "audiobooks/harpercollins-uk/titles/ISBN-9780008340537"
            #
            # 12. Castle in the Air
            "audiobooks/harpercollins-uk/titles/ISBN-9780008501075"
            #
            # 13. Eddie Albert and the Amazing Animal Gang: The Amsterdam...
            "audiobooks/harpercollins-uk/titles/ISBN-9780008446826"
            #

            #
            # Top Podcasts
            # ------------
            #
            # Stories Podcast
            "podcasts/stories-podcast-a-bedtime-show-for-kids-of-all-ages"
            #
            # But Why
            "podcasts/but-why-a-podcast-for-curious-kids"
            #
            # Goodnight stories for rebel girls
            "podcasts/good-night-stories-for-rebel-girls"
            #
            # Circle Round
            "podcasts/circle-round"
            #
            # Story Pirates
            "podcasts/story-pirates"
            #
            # Brains On!
            "podcasts/brains-on-science-podcast-for-kids"
            #

            #
            # Myths & legends
            # ---------------
            #
            # 1. Greek Myths
            "audiobooks/dk-uk/titles/ISBN-9780241474099"
            #
            # 2. Live from Mount Olympus (podcast)
            "podcasts/live-from-mount-olympus"
            #
            # 3. Moonchild: City of the Sun
            "audiobooks/harpercollins-uk/titles/ISBN-9780008496425"
            #
            # 4. The Lost Hero (Heroes of Olympus Book 1)
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141355788"
            #
            # 5. Greeking out
            "podcasts/greeking-out-from-national-geographic-kids"
            #
            # 6. The Outsiders (Gods and Warriors Book 1)
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141345789"
            #
            # 7. Children's Book of Mythical Beasts and Magical Monsters
            "audiobooks/dk-uk/titles/ISBN-9780241465226"
            #
            # 8. Tales of the Greek Heroes
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141932057"
            #
            # 9. Adventures in Time: Alexander the Great
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141998770"
            #
            # 10. Aesop’s Fables
            "audiobooks/harpercollins-uk/titles/ISBN-9780007376339"
            #
            # 11. Gladiator Clash
            "audiobooks/harpercollins-uk/titles/ISBN-9780007558865"
            #
            # 12. Myths, Legends & Sacred Stories
            "audiobooks/dk-uk/titles/ISBN-9780241475737"
            #

            #
            # Top radio stations
            # ------------------
            #
            # Rock the cradle
            "stations/rock-the-cradle"
            #
            # Fun Kids
            "stations/fun-kids"
            #
            # Kidjam
            "stations/kidjam-radio"
            #
            # Fun Kids Party
            "stations/fun-kids-party"
            #
            # Kids listen
            "stations/abc-kids-listen"
            #
            # Kids FM Radio
            "stations/kids-fm-radio"
            #

            #
            # Sleepy time
            # -----------
            #
            # 1. Ladybird Sleepy Tales
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241424339"
            #
            # 2. Puffin Sleepy Tales
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241424353"
            #
            # 3. Dreamful Bedtime Stories
            "podcasts/dreamful-bedtime-stories"
            #
            # 4. Bedtime History: Inspirational Stories for Kids and Families
            "podcasts/bedtime-history"
            #
            # 5. Ladybird Lullabies: Songs for Bedtime
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241478141"
            #
            # 6. Ladybird Favourite Fairy Tales
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241335062"
            #
            # 7. Stories Podcast: A Bedtime Show for Kids of All Ages
            "podcasts/stories-podcast-a-bedtime-show-for-kids-of-all-ages"
            #
            # 8. Peppa Pig: Bedtime Stories
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241299050"
            #
            # 9. Ten Minutes to Bed: Little Dragon
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241523445"
            #
            # 10. Kids meditation & sleep stories
            "podcasts/kids-meditation-sleep-stories"
            #
            # 11. The Little Elephant Who Wants to Fall Asleep
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780241291221"
            #
            # 12. Sleepytime Lullabies
            "audiobooks/harpercollins-uk/titles/ISBN-9780007451098"
            #
            # 13. Goodnight spaceman
            "audiobooks/penguinrandomhouse-uk/titles/ISBN-9780141378633"
            #
            # 14. Sleep Tight Relax - Helping kids become calm at bedtime
            "podcasts/sleep-tight-relax-helping-kids-become-calm-at-bedtime"
            #
            # 15. Sleep Tight Stories - Bedtime Stories for Kids
            "podcasts/sleep-tight-stories-bedtime-stories-for-kids"
            #
          ]
        ) {
          ... on Superfonik_Audiobook {
            ...audiobookFields
          }
          ... on Superfonik_Podcast {
            ...podcastFields
          }
          ... on Superfonik_Station {
            ...stationFields
          }
        }
        #
        # Items for collections
        #
        collections(
          storeCountry: "GB"
          id: [
            "paddington-bear-uk"
            "pooh-bear-uk"
            "dk-life-stories-uk"
            "dk-heads-up-uk"
            "roald-dahl-uk"
            "philip-pullman-uk"
            "rick-riordon-uk"
            "chronicles-of-narnia-uk"
          ]
          collectionLimit: 3 # Top three items from collection only
        ) {
          id
          ref
          slug
          storeCountry
          title
          items {
            ... on Superfonik_Audiobook {
              ...audiobookFields
            }
            ... on Superfonik_Podcast {
              ...podcastFields
            }
            ... on Superfonik_Station {
              ...stationFields
            }
          }
        }
      }
    }
  `)
}
