import BreakpointContext from "context/breakpointContext"
import { h2Responsive } from "helpers/headingSize"
import PropTypes from "prop-types"
import React, { useContext } from "react"

const SectionHeader = (props) => {
  const { text, verticalPadding = true, className } = props

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <>
      {bpt && (
        <p
          style={{
            fontSize: h2Responsive(bpt),
          }}
          className={`
            col-start-1 col-span-full
            font-bold
            tracking-wide laptop:tracking-normal desktop:tracking-tight
            select-none
            line-clamp-1
            ${
              verticalPadding
                ? `pt-7 tablet:pt-8 laptop:pt-10 desktop:pt-10
                   pb-3 tablet:pb-4 laptop:pb-5 desktop:pb-4`
                : ""
            }
            ${className ? className : ""}
          `}
        >
          {text}
        </p>
      )}
    </>
  )
}

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  verticalPadding: PropTypes.bool,
}

export default SectionHeader
