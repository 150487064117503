import Button from "components/controls/button"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import StationIcon from "components/icons/contentTypes/stationIcon"
import AspectRatioBox from "components/utils/aspectRatioBox"
import ClickableContainer from "components/utils/clickableContainer"
import RemoteImage from "components/utils/remoteImage"
import BreakpointContext from "context/breakpointContext"
import { useContentItem } from "context/contentItemsContext"
import { useGridDimensions } from "context/gridDimensionsContext"
import {
  SELECTED_ITEM,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { ContentType } from "helpers/contentTypes"
import { size } from "helpers/responsiveSize"
import { useCallToAction } from "hooks/useCallToAction"
import { useStoreLink } from "hooks/useStoreLink"
import PropTypes from "prop-types"
import React, { memo, useContext } from "react"

//
// Store carousel banner slides
//
const CarouselBannerSlideVertical = memo(({ title, subtitle, contentRef }) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Grab the content item from the context
  const contentItem = useContentItem(contentRef)

  // Get CTA detail
  const { isLoaded: isCTALoaded, text: ctaText } = useCallToAction(contentItem)

  // Set selected item using dispatcher from context
  const dispatchSelectedItem = useSelectedItemDispatchContext()

  // Grid dimensions from context
  const { offsetWidth } = useGridDimensions()

  // Store link for item
  const storeLink = useStoreLink(contentRef)

  return (
    <div
      style={{
        marginLeft: offsetWidth ? offsetWidth : 0,
        marginRight: offsetWidth ? offsetWidth : 0,
        paddingTop: size(bpt, "2vw"),
        paddingBottom: size(bpt, "2vw"),
      }}
    >
      {/* Artwork */}
      <ClickableContainer
        shadows="neumorphic-4-outer"
        shadowsPressed="neumorphic-4-inner"
        rounded="rounded-3xl"
        className="col-span-6 overflow-hidden flex"
        href={storeLink}
        onClick={() => {
          dispatchSelectedItem({
            type: SELECTED_ITEM,
            payload: contentItem,
          })
        }}
      >
        <AspectRatioBox mobileRatio="100%" className="w-full">
          {contentItem?.artwork && (
            <RemoteImage
              imagePath={contentItem.artwork}
              mobileImageSize={220}
              aspectRatio="1:1"
              className="w-full h-auto"
            />
          )}
        </AspectRatioBox>
      </ClickableContainer>

      {/* Content type */}
      <div
        className="flex items-center"
        style={{
          marginTop: "10vw",
        }}
      >
        {/* Content icons */}
        <div
          style={{
            height: "4vw",
            width: "4vw",
            marginRight: "1.5vw",
          }}
        >
          {contentItem?.type === ContentType.AUDIOBOOK && (
            <AudiobookIcon alignment="right" />
          )}
          {contentItem?.type === ContentType.PODCAST && (
            <PodcastIcon alignment="right" />
          )}
          {contentItem?.type === ContentType.STATION && (
            <StationIcon alignment="right" />
          )}
        </div>
        {/* Content label */}
        <div
          className="font-bold"
          style={{
            fontSize: "3.5vw",
            marginTop: "0.5vw",
          }}
        >
          {contentItem?.type === ContentType.AUDIOBOOK && (
            <span className="text-blue">Audiobook</span>
          )}
          {contentItem?.type === ContentType.PODCAST && (
            <span className="text-orange">Podcast</span>
          )}
          {contentItem?.type === ContentType.STATION && (
            <span className="text-red">Station</span>
          )}
        </div>
      </div>

      {/* Title */}
      <div
        className="font-bold leading-tight line-clamp-2"
        style={{
          fontSize: size(bpt, "5.25vw", "3vw", "3vw", "3vw"),
          marginTop: "3vw",
        }}
      >
        {title}
      </div>

      {/* Subtitle */}
      <div
        className="font-normal leading-tight line-clamp-3"
        style={{
          fontSize: "3.8vw",
          marginTop: "2vw",
        }}
      >
        {subtitle}
      </div>

      {/* CTA button */}
      {ctaText && (
        <Button
          text={ctaText}
          type="primary"
          loading={!isCTALoaded}
          shadowHeight={3}
          size="sm"
          style={{
            width: "30vw",
            marginTop: "4.5vw",
          }}
          className="text-grey-light"
          onClick={() => {
            dispatchSelectedItem({
              type: SELECTED_ITEM,
              payload: contentItem,
            })
          }}
        />
      )}
    </div>
  )
})

CarouselBannerSlideVertical.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  contentRef: PropTypes.string.isRequired,
}

export default CarouselBannerSlideVertical
CarouselBannerSlideVertical.whyDidYouRender = true
